<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ChatSessionReport",
  data() {
    return {
      resource: [],
      resourceLoading: false,
      startDate: null,
      endDate: null,
      selected_agent: null,
      selected_alias: null,
      search_agent: null,
      search_alias: null,
      agents_list: [],
      alias_list: [],
      selected_client: null,
      search_client: null,
      client_list: [],
      notAnswered: null,
      search_timeout: null,
      loading: false
    }
  },
  methods: {
    async getResource(){
      if(this.resourceLoading) return
      this.resourceLoading = true
      this.resource = null
      await this.axios.get('reports/chat_sessions', {
        params: {
          from: this.startDate,
          to: this.endDate,
          agent_id: this.selected_agent,
          alias_id: this.selected_alias,
          chat_user_id: this.selected_client,
          include_unanswered: this.notAnswered
        }
      }).then((resources) => {
        this.resource = resources.data
      }).catch ((error) => {
        console.error(error);
      });
      this.resourceLoading = false
    },
    date(date) {
      return this.$day(date).format("DD/MM/YYYY");
    },
    timeOfDay(date) {
      return this.$day(date).format("HH:mm");
    },
    formatDuration(seconds) {

      const duration = this.$day.duration(seconds, "seconds");
      const minutes = duration.minutes();
      const remainingSeconds = duration.seconds();
      return `${minutes} min. ${remainingSeconds} sek.`;

    },
    endReason(reason) {
      switch (reason) {
        case "client ended":
          return "Asiakas lopetti";
        case "agent ended":
          return "Agentti lopetti";
        case "client left":
          return "Asiakas poistui";
        case "agent left":
          return "Agentti poistui";
        case "credits out":
          return "Asiakkaan krediitit loppuivat";
        case "doorbell timeout":
          return "Agentti ei vastannut";
        default:
          return reason;
      }
    },
  },
  watch: {
    search_alias(){
      clearTimeout(this.search_timeout)
      if(this.search_alias)
        this.search_timeout = setTimeout(() => {
          this.loading = true
          this.axios.get('chat_aliases', {
            params: {
              skip: 0,
              take: 500,
              order: 'name',
              order_direction: 'ASC',
              name: this.search_alias,
              type: 2
            }
          }).then((response)=>{
            this.alias_list = response.data
            this.loading = false
          })
        }, 500)
    },
    search_client(){
      clearTimeout(this.search_timeout)
      if(this.search_client)
        this.search_timeout = setTimeout(() => {
          this.loading = true
          this.axios.get('consumers/chat_user', {
            params: {
              keyword: this.search_client,
            }
          }).then((response)=>{
            this.client_list = response.data
            this.loading = false
          })
        }, 500)
    },
  },
  mounted(){
    this.startDate = this.$day().startOf('month').format("DD.MM.YY")
    this.endDate = this.$day().format("DD.MM.YY")
    this.getResource()

    this.axios.get('reports/agent_list', {
      params: {
        feature: ['2','4'],
      }
    }).then((r) => {
      this.agents_list = r.data
    }) //get agents_list
  }
})
</script>

<template>
  <main>
    <h1 class="headline blue--text text-center mt-4 mb-3">Aikatauluperusteisten keskustelujen raportti</h1>
    <v-divider></v-divider>
    <v-row dense>
      <v-col cols="2">
        <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
      </v-col>
      <v-col>
        <v-autocomplete
            v-model="selected_agent"
            :search-input.sync="search_agent"
            :items="agents_list"
            item-value="agent_id"
            item-text="name"
            label="Etsi agentti"
            clearable
        >
          <template #item="{ item }">
            {{ item.name }}
          </template>
          <template #selection="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
            v-model="selected_alias"
            :search-input.sync="search_alias"
            :items="alias_list"
            :return-object="false"
            item-value="id"
            item-text="name"
            label="Etsi alias"
            clearable
        >
          <template #item="{ item }">
            {{ item.name }}
          </template>
          <template #selection="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
            v-model="selected_client"
            :search-input.sync="search_client"
            :items="client_list"
            :return-object="false"
            item-value="id"
            item-text="name"
            label="Etsi asiakas"
            clearable
        >
          <template #item="{ item }">
            {{ item.name }}
          </template>
          <template #selection="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense class="pt-0 mt-0">
      <v-col cols="3" class="pt-0">
        <v-checkbox v-model="notAnswered" label="Vastaamattomat keskustelut"/>
      </v-col>
      <v-col cols="2">
        <v-btn
            class="v-btn ma-3 green"
            @click="getResource"
            small
            :disabled="resourceLoading"
        >
          HAE
        </v-btn>
      </v-col>
    </v-row>

    <v-card v-if="resource && resource.chat_sessions" class="mb-2">
      <v-card-text>
        Keskusteluja: <b class="white--text">{{ resource.chat_sessions_count }}</b>kpl,
        Krediittejä veloitettu: <b class="white--text">{{ resource.income_in_credits }}</b>,
        Keskimääräinen kesto: <b class="white--text">{{ resource.median_length_of_session }}</b>min,
        Keskustelujen mediaani: <b class="white--text">{{ resource.average_length_of_session }}</b>min,
        Keskimääräinen viestien määrä: <b class="white--text">{{ resource.average_messages_per_session }}</b>kpl
        <br/>
        <template v-if="resource.agent_ended_count > 0">
          agentti lopetti: <b class="white--text">{{ resource.agent_ended_count }}kpl</b>,
        </template>
        <template v-if="resource.agent_left_count > 0">
          agentti poistui: <b class="white--text">{{ resource.agent_left_count }}kpl</b>,
        </template>
        <template v-if="resource.client_ended_count > 0">
          asiakas lopetti: <b class="white--text">{{ resource.client_ended_count }}kpl</b>,
        </template>
        <template v-if="resource.credit_outs_count > 0">
          krediitit loppuivat: <b class="white--text">{{ resource.credit_outs_count }}kpl</b>,
        </template>
        <template v-if="resource.client_left_count > 0">
          asiakas poistui: <b class="white--text">{{ resource.client_left_count }}kpl</b>,
        </template>
        <template v-if="resource.door_bell_timeout_count > 0">
          agentti ei vastannut: <b class="white--text">{{ resource.door_bell_timeout_count }}kpl</b>,
        </template>
      </v-card-text>
    </v-card>
    <v-card class="mb-16" v-if="resource">
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>
                Aika
              </th>
              <th>
                Agentti
              </th>
              <th>
                Alias-asiakas
              </th>
              <th>
                Pituus
              </th>
              <th>
                Krediitit
              </th>
              <th>
                Loppusyy
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(tr) in resource.chat_sessions">
              <tr :key="'tr'+tr.id" :bgcolor="!tr.started_at ? '#750000' : null">
                <td>
                  <template v-if="!tr.started_at">
                    {{ date(tr.created_at) }}
                    {{ timeOfDay(tr.created_at) }}
                    {{ endReason(tr.end_reason) }}
                  </template>
                  <template v-else>
                    {{ date(tr.started_at) }}
                    {{ timeOfDay(tr.started_at) }} -
                    <template
                        v-if="date(tr.started_at) != date(tr.ended_at)"
                    >
                      {{ date(tr.ended_at) }} {{ timeOfDay(tr.ended_at) }}
                    </template>
                    <template v-else>
                      {{ timeOfDay(tr.ended_at) }}
                    </template>
                  </template>
                </td>
                <td>
                  {{ tr.agent.name }}
                </td>
                <td>
                  {{ tr.alias.name }} <span style="font-size: 0.8em">({{ tr.alias_messages_count }})</span> /{{ tr.chat_user.name }} <span style="font-size: 0.8em">({{ tr.client_messages_count }})</span>
                </td>
                <td>
                  <template v-if="tr.duration">
                    {{ formatDuration(tr.duration) }}
                  </template>
                  <template v-else>-</template>
                </td>
                <td>
                  {{ (Math.round(tr.duration/60) * 115) }}
                </td>
                <td>
                  <template v-if="tr.duration">
                    {{ endReason(tr.end_reason) }}<br/>
                    <span style="font-size: 0.8em">Viestejä: {{ tr.messages_count }}</span>
                  </template>
                  <template v-else>-</template>

                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </main>
</template>

<style scoped>

</style>