<template>
  <main>
    <h1 class="headline blue--text text-center mt-4 mb-3">Suoramarkkintointikampanjat</h1>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="2">
        <v-text-field v-model="startDate" placeholder="01.12.23" label="alkupäivä"/>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="endDate" placeholder="31.12.23" label="loppupäivä"></v-text-field>
      </v-col>
      <v-col>
        <v-autocomplete
            v-model="selected_subproject"
            :items="subprojects_list"
            :search-input="search"
            :return-object="false"
            item-value="id"
            item-text="name"
            label="Valitse aliprojekti"
            clearable
        >
          <template #item="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-btn
            class="v-btn ma-3 green float-right"
            small
            @click="getResource"
        >
          HAE
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn
            class="v-btn mt-3 mb-0 green float-right"
            :to="'/direct_marketing/uusi'"
            small
        >
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon> Uusi kampanja
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="mb-16" v-if="!resourceLoading">
      <v-card-text>
        <v-simple-table>
          <thead>
          <tr>
            <th>
              ID
              <v-icon v-if="orderedBy != 'id'" @click="orderedBy = 'id'">mdi-arrow-down-right</v-icon> <!-- default state -->
              <v-icon v-if="orderedBy == 'id' && orderedByStep == 1" @click="orderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
              <v-icon v-if="orderedBy == 'id' && orderedByStep == 2" @click="orderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
            </th>
            <th>
              Viesti
            </th>
            <th>
              Lisätty
            </th>
            <th>
              Lähetys alkaa
              <v-icon v-if="orderedBy != 'start_send_date_ts'" @click="orderedBy = 'start_send_date_ts'">mdi-arrow-down-right</v-icon> <!-- default state -->
              <v-icon v-if="orderedBy == 'start_send_date_ts' && orderedByStep == 1" @click="orderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
              <v-icon v-if="orderedBy == 'start_send_date_ts' && orderedByStep == 2" @click="orderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
            </th>
            <th>
              Lähetys loppuu
            </th>
            <th>
              Lähettäjä
              <v-icon v-if="orderedBy != 'sender'" @click="orderedBy = 'sender'">mdi-arrow-down-right</v-icon> <!-- default state -->
              <v-icon v-if="orderedBy == 'sender' && orderedByStep == 1" @click="orderedByStep++">mdi-arrow-down</v-icon> <!-- desc order state -->
              <v-icon v-if="orderedBy == 'sender' && orderedByStep == 2" @click="orderedByStep++">mdi-arrow-up</v-icon> <!-- asc order state -->
            </th>
            <th>
              Määrä
            </th>
            <th>
              Aliprojekti
            </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(r,key) in resources" :key="key"  class="cursor-pointer" @click="selected_campaign = r;dialog=true" :class="{'green': r.state == 1, 'orange': r.state == 2}">
              <td>
                {{ r.id }}
              </td>
              <td>
                <div class="mt-2 mb-2" style="max-width: 500px">
                  {{ r.text }}
                </div>
              </td>
              <td>
                {{ r.created }}
              </td>
              <td>
                {{ r.start_send_date }}
              </td>
              <td>
                {{ r.end_send_date }}
              </td>
              <td>
                {{ r.sender }}
              </td>
              <td>
                {{ r.count }}
              </td>
              <td>
                {{ r.sub_project.name }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-text>
        Ladataan...
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" max-width="700px">
      <v-card style="border: 1px solid white;">
        <v-card-text>
          {{ selected_campaign.text }}<br/>
          <b>Ehdot:</b>
          <v-list v-if="selected_campaign.ehdot && selected_campaign.ehdot.length > 0">
            <v-list-item v-for="d in selected_campaign.ehdot" :key="d.id" style="border-top: white 1px solid">{{ d.infotext }}</v-list-item>
          </v-list>
          <template v-if="message_status">
            <v-list style="border-top: white 1px solid">
              <v-list-item v-if="message_status.delivered_count">Toimitettuja: {{ message_status.delivered_count }}</v-list-item>
              <v-list-item v-if="message_status.sent_to_gw_count">Lähetetty gatewaylle: {{ message_status.sent_to_gw_count }}</v-list-item>
              <v-list-item v-if="message_status.kielto_count">Kieltolistalla: {{ message_status.kielto_count }}</v-list-item>
              <v-list-item v-if="message_status.operator_error_state_count">Operaattorivirheitä: {{ message_status.operator_error_state_count }}</v-list-item>
              <v-list-item v-if="message_status.max_messages_per_day_reached_count">Viestimäärä päivässä täynnä: {{ message_status.max_messages_per_day_reached_count }}</v-list-item>
              <v-list-item v-if="message_status.max_messages_per_week_reached_count">Viestimäärä viikossa täynnä: {{ message_status.max_messages_per_week_reached_count }}</v-list-item>
              <v-list-item v-if="message_status.sms_gw_error_count">Gateway-virhe: {{ message_status.sms_gw_error_count }}</v-list-item>
              <v-list-item v-if="message_status.bad_numbers_count">Vääriä numeroita: {{ message_status.bad_numbers_count }}</v-list-item>
            </v-list>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn class="green" :to="'/direct_marketing/'+selected_campaign.id+'/muokkaa'" v-if="selected_campaign.start_send_date_ts > currentTS">Muokkaa</v-btn>
          <v-btn class="green" :to="'/direct_marketing/'+selected_campaign.id+'/kopioi'">Käytä pohjana uudelle</v-btn>
          <v-btn class="red" v-if="selected_campaign.state != 3" @click="deleteItem(selected_campaign.id)">Poista</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- snackbar message -->
    <v-snackbar
        v-model="snackbar"
        :timeout="snackbarTimeout"
        :color="snackbarColor"
    >
      <div class="text-center font-weight-bold">
        {{ snackbarMessage }}
      </div>
    </v-snackbar>
  </main>
</template>
<script>
export default {
  name: "DirectMarketing",
  data: function () {
    return {
      selected_subproject: null,
      subprojects_list: [],
      search: "",
      resourceLoading: null,
      resources: [],
      orderedBy: null,
      orderedByStep: 0,
      resources_original: [],
      dialog: false,
      selected_campaign: {},
      startDate: null,
      endDate: null,
      currentTS: null,
      snackbar: false,
      snackbarTimeout: 5000,
      snackbarMessage: null,
      snackbarColor: null,
      message_status: {}
    }
  },
  watch: {
    selected_campaign(){
      if(this.selected_campaign?.id) this.axios.get('sms_marketing/'+this.selected_campaign.id+'/messages_status').then(r => {
        this.message_status = r.data
      })
    },
    selected_subproject(){

      if(this.resources_original.length == 0) this.resources_original = [...this.resources];
      if(this.selected_subproject)
        this.resources = this.resources_original.filter(d=> d.sub_project_id == this.selected_subproject)


    },
    orderedBy(){

      if(this.resources_original.length == 0) this.resources_original = [...this.resources];
      if(this.orderedBy) {
        this.orderedByStep = 1
        this.orderResources()
      }
    },
    orderedByStep(){

      //going over step 2 will reset the order
      if(this.orderedByStep > 2){
        this.orderedByStep = 0
        this.orderedBy = null,
            this.resources = [...this.resources_original];
      }else
        this.orderResources()
    },

  },
  methods: {

    deleteItem(id){

      this.resources.splice(this.resources.findIndex(x => x.id == id), 1)

      this.axios.delete('sms_marketing/'+id)

      this.snackbarMessage = "Poistettu kampanja "+id
      this.snackbar = true
      this.snackbarColor = 'green'
      this.dialog = false

    },

    orderResources(){

      if(this.orderedByStep == 1){
        this.resources.sort(function(a, b) {
          if(
              this.orderedBy == 'sender' || this.orderedBy == 'start_send_date'
          ){
            const propA = a[this.orderedBy].toLowerCase(); // Convert to lowercase for case-insensitive sorting
            const propB = b[this.orderedBy].toLowerCase();

            return propB.localeCompare(propA);
          }
          else
          return b[this.orderedBy] - a[this.orderedBy];
        }.bind(this));
      }

      if(this.orderedByStep == 2){
        this.resources.sort(function(a, b) {
          return a[this.orderedBy] - b[this.orderedBy];
        }.bind(this));
      }

      console.log('orderResources')

    },
    async getResource(){
      if(this.resourceLoading) return
      this.resourceLoading = true
      this.resources = null
      await this.axios.get('sms_marketing', {
        params: {
          from: this.startDate,
          to: this.endDate,
        }
      }).then((resources) => {
        this.resources = resources.data
      }).catch ((error) => {
        console.error(error);
      });
      this.resourceLoading = false
    },
  },
  mounted() {

    this.startDate = this.$day().subtract(28, 'day').format("DD.MM.YYYY")
    this.endDate = this.$day().add(28, 'day').format("DD.MM.YYYY")

    this.axios.post('reports/subprojects_list', {
      projects: []
    }).then((r) => {
      this.subprojects_list = r.data
    }) //get subprojects_list (projects limit results
    this.getResource()

    this.currentTS = this.$day().valueOf();
  },
}
</script>